<template>
  <v-form v-model="formValid">
    <v-container fluid>
      <v-row id="single_search" class="rounded px-4 py-8 elevation-3">
        <v-col cols="12" xs="12" sm="6" md="3">
          <NominatimSearch
            v-model="locality"
            :search-text="searchText"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3">
          <vc-date-picker
            v-model="dateFilter"
            is-range
            :columns="2"
            color="blue"
            :min-date="new Date()"
            :popover="{ visibility: 'focus' }">
            <template v-slot="{ dateFilter: inputValue, inputEvents }">
              <div class="d-flex justify-center items-center">
                <v-text-field
                  outlined
                  :value="getLocalizedDate(dateFilter.start)"
                  v-on="inputEvents.start"
                  hide-details
                  :rules="[validation.required]"
                  readonly
                  placeholder="Od"
                  class="rounded-0 border-right-none"
                />
                <v-icon color="" style="border-radius: 0px; border-top: 1px rgba(0, 0, 0, 0.38) solid; border-bottom: 1px rgba(0, 0, 0, 0.38) solid;padding: 15px 0 15px 0; background-color: white">mdi-arrow-right-thick</v-icon>
                <v-text-field
                  class="rounded-0 border-left-none"
                  outlined
                  :rules="[validation.required]"
                  :value="getLocalizedDate(dateFilter.end)"
                  v-on="inputEvents.end"
                  hide-details
                  readonly
                  placeholder="Do"
                />
              </div>
            </template>
          </vc-date-picker>
        </v-col>
        <v-col class="align-self-center" cols="12" xs="12" sm="6" md="4">
          <v-menu
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-flex disabled-normal-text"
              >
                <v-text-field
                   filled
                   hint="test"
                  class="rounded-0"
                  :value="form.adults"
                  label="Dospělí"
                  prepend-inner-icon="mdi-account"
                   hide-details
                   disabled
                >
                </v-text-field>
                <v-text-field
                  filled
                  hint="test"
                  class="rounded-0 disabled-normal-text"
                  :value="form.children"
                  label="Děti"
                  prepend-inner-icon="mdi-baby-carriage"
                  hide-details
                  disabled
                >
                </v-text-field>
                <v-text-field
                  filled
                  hint="test"
                  class="rounded-0"
                  :value="form.rooms"
                  :rules="[validation.required, validation.greaterThan(0)]"
                  label="Pokoje"
                  prepend-inner-icon="mdi-bed"
                  hide-details
                  disabled
                >
                </v-text-field>
              </div>
            </template>
            <v-card>
              <v-container class="menu-popover">
                <v-row>
                  <v-col><div>Dospělí</div></v-col>
                  <v-col class="d-flex">
                    <v-btn outlined color="primary" class="rounded-0" @click="decrement('adults')">-</v-btn>
                    <div class="align-self-center menu-count">{{form.adults}}</div>
                    <v-btn outlined color="primary" class="rounded-0" @click="increment('adults')">+</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><div>Děti</div></v-col>
                  <v-col class="d-flex">
                    <v-btn outlined color="primary" class="rounded-0" @click="decrement('children')">-</v-btn>
                    <div class="align-self-center menu-count">{{form.children}}</div>
                    <v-btn outlined color="primary" class="rounded-0" @click="increment('children')">+</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><div>Pokoje</div></v-col>
                  <v-col class="d-flex">
                    <v-btn outlined color="primary" class="rounded-0" @click="decrement('rooms')">-</v-btn>
                    <div class="align-self-center menu-count">{{form.rooms}}</div>
                    <v-btn outlined color="primary" class="rounded-0" @click="increment('rooms')">+</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="2">
          <v-btn
            color="light-blue accent-3"
            x-large
            class="rounded text-lowercase text-white"
            :disabled="!searchValid"
            style="width: 100%"
            @click="search"
          >Vyhledat
            <v-icon class="ml-2">
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import NominatimSearch from '@/components/search/NominatimSearch'

export default {
  name: 'SimpleSearchForm',
  components: { NominatimSearch },
  data () {
    return {
      formValid: true,
      // locality ve formu neni, kvuli tomu, aby se dobre provazoval s vuexem
      form: {
        adults: 0,
        children: 0,
        rooms: 0
      },
      searchText: '',
      dateFilter: {
        start: null,
        end: null
      }
    }
  },
  computed: {
    searchValid () {
      if (+this.form.adults + +this.form.children === 0) {
        return false
      }
      if (!this.dateFilter.start) {
        return false
      }
      if (!this.form.rooms) {
        return false
      }
      if (!this.locality) {
        return false
      }
      return true
    },
    locality: {
      get () {
        return this.$store.state.search.searchParameters.locality
      },
      set (val) {
        this.$store.state.search.searchParameters.locality = val
      }
    },
    ...mapGetters({
      searchParameters: 'search/searchParameters'
    })
  },
  methods: {
    decrement (name) {
      if (this.form[name] !== 0) {
        --this.form[name]
      }
    },
    increment (name) {
      ++this.form[name]
    },
    search () {
      this.$emit('search-init', {
        ...this.form,
        locality: this.locality.displayName,
        dateFrom: this.dateFilter.start.toISOString().slice(0, 10),
        dateTo: this.dateFilter.end.toISOString().slice(0, 10)
      })
    },
    ...mapMutations({
      setSearchParameters: 'search/setSearchParameters'
    })
  },
  created () {
    // reload nominatim
    this.locality = this.searchParameters.locality || {}
    this.form.adults = this.searchParameters.adults || 0
    this.form.children = this.searchParameters.children || 0
    this.form.rooms = this.searchParameters.rooms || 0
    this.dateFilter.start = this.searchParameters.dateFrom ? new Date(this.searchParameters.dateFrom) : null
    this.dateFilter.end = this.searchParameters.dateTo ? new Date(this.searchParameters.dateTo) : null
  },
  beforeDestroy () {
    this.setSearchParameters({})
  }
}
</script>

<style scoped>
.text-white{
  color: white !important;
}
#single_search{
  background-color: white !important;
}
#single_search .theme--light.v-input{
  background-color: white;
}
>>>.border-left-none fieldset{
  border-left: none !important;
}
>>>.border-right-none fieldset{
  border-right: none !important;
}
>>>.disabled-normal-text input, >>>.disabled-normal-text label{
  color: rgba(14, 14, 14, 0.87) !important;
}
.menu-count{
  padding: 0 15px 0 15px;
}
>>>.menu-popover button{
  min-width: 44px !important;
}
</style>
